.CustomerInsight {
    background-color: transparent;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0px;
    height: fit-content;
    gap: 10px; 
}

.widget{
    background-color:white;
    display: flex; 
    flex: 1;
    padding: 10px;
    justify-content: space-between;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);
    box-shadow: 2px 4px 10px 1px rgba(201,201,201,0.47);
    height: 100px;
}

.left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title{
    font-weight: Bold;
    font-size: 18px;
    color: rgba(160,160,160);
}

.counter{
    font-size: 28px;
    font-weight: 300;
}

.link{
    width: max-content;
    font-size: 12px;
}

.percentagePos{
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: normal;
    color: green; 
}

.percentageNeg{
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: normal;
    color: red;
}

.icon{
    padding: 0px;
    align-self: flex-end;
    border-radius: 5px;
}

.widgetContainer{
    background-color: transparent;
    padding: 0px;
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
}



.loadingText{
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: large;
    font-weight:bolder;
    color: gray;
    height: 100%;
    margin: 100px;
}

.loadingContainer {
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: large;
    font-weight:bolder;
    color: gray;
    height: 100%;
    margin: 100px;
}

.loadingIcon{
    justify-content: space-around;
    color: gray;
    margin: 0px 10px;
    animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.topBarContainer {
    background-color: transparent;
    height: 50px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.topLeftWrapper {
    width: 100%;
    font-weight:bolder;
    font-size: 25px;
}

.topMidWrapper {
    text-align: center;
    width: 100%;
    height: 50%;
    align-items: center;
}

.topRightWrapper {
    width: 100%;
    justify-content: right;
    text-align: center;
    display: flex;
    gap: 10px;
}

.graphHeaderTopLeftWrapper {
    background-color: transparent;
    justify-content: space-around;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.graphHeaderTopRightWrapper {
    background-color: transparent;
    justify-content: space-around;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.graphHeaderSelectTitleWrapper {
    justify-content: space-around;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.periodTitle {
    justify-content: center;
    text-align: center;
    font-weight:normal;
    font-size: 20px;
}

.periodSelector {
    justify-content: center;
    text-align: center;
    font-weight:normal;
    font-size: 20px;
}

.graphContainer {
    background-color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
}

.graphWrapper {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
}

.graphHeader {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.channelGraphHeader {
    background-color: transparent;
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.channelContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    background-color: transparent;
    padding: 0px;
    justify-content: space-evenly;
}

.channelGraphContainer {
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    justify-content: space-around;
    flex: 1;
}

.channelDiagramContainer {
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    justify-content: space-between;
    flex: 1;
}

.diagramContainer {
    display: flex;
    background-color: transparent;
    justify-content: space-around;
    padding: 0px;
}


