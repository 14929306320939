#root {
  height: 100vh;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.squares {
  opacity: 0.1;
  background-image: linear-gradient(45deg, #000 25%, transparent 25%), linear-gradient(-45deg, #000 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #000 75%), linear-gradient(-45deg, transparent 75%, #000 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.pulse {
  box-shadow: 0 0 0 rgba(255, 0, 145, 0.5);
  animation-name: pulsekey !important;
  animation-timing-function: linear !important;
  animation: pulsekey 2s infinite;
}
.pulse:hover {
  animation: none;
}
.view {
  min-height: calc(100vh - 64px);
}

@-webkit-keyframes pulsekey {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 145, 0.5);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 0, 145, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 145, 0);
  }
}
@keyframes pulsekey {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 0, 145, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 0, 145, 0.5);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(255, 0, 145, 0);
    box-shadow: 0 0 0 10px rgba(255, 0, 145, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 0, 145, 0);
    box-shadow: 0 0 0 0 rgba(255, 0, 145, 0);
  }
}
